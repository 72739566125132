export default [
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/core/RegisterView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/core/LoginView.vue')
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/AppsView.vue')
  },
  {
    path: '/contacts/:page',
    name: 'Contact Book',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/ContactBookView.vue')
  },
  {
    path: '/patients',
    name: 'Patients',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/PatientsView.vue')
  },
  {
    path: '/settings',
    name: 'Account General Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/core/settings/AccountGeneralSettingsView.vue')
  },
  {
    path: '/settings/services',
    name: 'Account Service Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/core/settings/AccountServiceSettingsView.vue')
  },
  {
    path: '/settings/users',
    name: 'Account Users',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/core/settings/AccountUsersView.vue')
  },
  {
    path: '/settings/myself',
    name: 'User Personal Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/core/settings/UserPersonalSettingsView.vue')
  },
  {
    path: '/invite/:url',
    name: 'Accept Invite',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/AcceptInviteView.vue')
  }
]