<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import '@/assets/theme.css';
@import '@/assets/theme-v2.css';
@import '@/assets/tiptap.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Lucida Fax';
  src: url('@/assets/fonts/lucida-fax-regular.ttf');
}

:root {
  --color-link: #3a71fb;
}

.app {
  overflow-x: hidden;
}

html {
  /* Stops messages from getting wonky font sizes */
  -webkit-text-size-adjust: 100%;
  /* Safari and Chrome */
  -ms-text-size-adjust: 100%;
  /* IE/Edge */
  text-size-adjust: 100%;
  /* Standard */
}

* {
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

#full {
  background: #FFF;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
}

.title {
  margin-bottom: 5px;
}

.subtitle {
  line-height: 24px;
}

p {
  line-height: 24px;
}

/* Modals */

.modal-slide-enter-active,
.modal-slide-leave-active {
  transition: all ease-in-out 0.25s, left 0.01s, right 0.01s, top 0.01s, bottom 0.01s;
  transform: none;
  opacity: 1;
}

.modal-slide-enter-from {
  transform: translateX(10px);
  opacity: 0;
}

.modal-slide-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all ease-in-out 0.25s;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-pop-enter-active,
.modal-pop-leave-active {
  transition: all ease-in-out 0.25s, left 0.01s, right 0.01s, top 0.01s, bottom 0.01s;
  transform: none;
  opacity: 1;
}

.modal-pop-enter-from,
.modal-pop-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.modal-appear-enter-active,
.modal-appear-leave-active {
  transition: all ease-in-out 0.25s, left 0.01s, right 0.01s, top 0.01s, bottom 0.01s;
  transform: none;
  opacity: 1;
}

.modal-appear-enter-from,
.modal-appear-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.dropdown-appear-enter-active,
.dropdown-appear-leave-active {
  transition: all ease-in-out 0.25s;
}

.dropdown-appear-enter-from,
.dropdown-appear-leave-to {
  opacity: 0;
  transform-origin: top;
  transform: translateY(-10px);
}

.loading {
  display: block;
  width: 48px;
  height: 24px;
  margin: auto;
}

.button.small .loading {
  height: 20px;
  width: 40px;
}

.error {
  color: #960030;
  display: block;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

.indicator-icon {
  display: inline-flex;
  width: 8px;
  height: 8px;
  background: #683fff;
  border-radius: 50%;
  color: #FFF;
  font-size: 8px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.required {
  opacity: 0.5;
}
</style>
