export default [
  {
    name: 'My Tasks',
    path: '/my-tasks/:id?',
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/PersonalTasksView.vue')
  },
  {
    name: 'Team Tasks',
    path: '/team-tasks/:id?',
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/TeamTasksView.vue')
  },
]
